/**
 * @deprecated DO NOT USE THIS TYPE. Prefer FeatureFlagsKeys and FeatureFlagsValues instead.
 * Feature flags are used to enable/disable features in the app
 */
export interface FeatureFlags {
  feature_permissions: FeaturePermissions;
  unifiedProgressCheckSurvey: boolean;
}

export enum FeatureFlagsKeys {
  MFA_PROFESSIONAL = "mfa_professional",
  HYBRID_CARE_AI_ENABLED = "hybrid_care_ai_enabled",
  COMPANION_PAGE = "companion_page_enabled_web",
  COMPANION_MODEL_VERSION = "companion_model_version",
}

export interface FeatureFlagsValues {
  [FeatureFlagsKeys.MFA_PROFESSIONAL]: boolean;
  [FeatureFlagsKeys.HYBRID_CARE_AI_ENABLED]: boolean;
  [FeatureFlagsKeys.COMPANION_PAGE]: boolean;
  [FeatureFlagsKeys.COMPANION_MODEL_VERSION]: number;
}

/**
 * Feature permissions are used to enable/disable features for specific users
 */
export interface FeaturePermissions {
  chat_as_care_navigator: Array<string>;
  chat_as_clinical_supervisor: Array<string>;
  chat_as_oliva_customer_support: Array<string>;
  admin_tools: Array<string>;
}
