import uniq from "lodash/uniq";

import {
  INTERCOM_FAQ_URL,
  METABASE_SESSION_LOG_URL,
} from "@olivahealth/constants";
import UserRole from "@olivahealth/graphql-server/src/domain/value-objects/UserRole";
import tokens from "@olivahealth/oli-ui/theme/tokens";
import {
  BriefCaseIcon,
  CalendarIcon,
  ChatBalloonIcon,
  CounterDot,
  GroupIcon,
  HeartIcon,
  HomeIcon,
  MeditationIcon,
  NavbarLink,
  NavbarLinkType,
  PhoneIcon,
  PieChartIcon,
  QuestionCircleIcon,
  ResourcesIcon,
  Tag,
  UserIcon,
  UsersIcon,
} from "@olivahealth/oli-ui";
import {
  containsEmployeeRole,
  containsSupervisorRole,
} from "@olivahealth/utils/helpers/user";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { FeatureFlagsKeys } from "@olivahealth/utils/types/FeatureFlags";
import { OliIcon } from "@olivahealth/oli-ui/src/atoms/Icons/Oli";
import { OlivaHook } from "../../../hooks/OlivaHook";
import useTranslation, { TFunction } from "../../../hooks/useTranslation";
import { useChat } from "../../../services/contexts/ChatContext";
import { useSharedStoreContext } from "../../../services/contexts/SharedStoreContext";
import {
  IUserDataContext,
  useUserData,
} from "../../../services/contexts/UserDataContext";
import { PAGE_ROUTE } from "../../../utils/routing/routes";
import useCanAccessProfessionalCoaching from "../../../hooks/useCanAccessProfessionalCoaching";
import useHasCoachingOnlyAllocation from "../../../hooks/useHasCoachingOnlyAllocation";
const LINK_ORGANISATION_TEAM_SESSIONS_ID = "navbar-link-team-sessions";
const LINK_PROFILE_ID = "navbar-link-profile";
const LINK_SUPPORT_ID = "navbar-link-1-to-1";

interface UseNavbarData {
  isOrganisationAdmin: boolean;
  links: NavbarLink[];
}

interface GetNavbarLinksProps {
  user: IUserDataContext["data"];
  isProfessional: boolean;
  canAccessProfessionalCoaching: boolean;
  isCompanionPageEnabled: boolean;
  unreadMessagesCount: number;
  crisisSupportEnabled: boolean;
  hasSurveyProfessionalNPSPendingSubmission: boolean;
  t: TFunction;
}

type UseNavbar = OlivaHook<UseNavbarData>;

export default function useNavbar(): UseNavbar {
  const { data: user } = useUserData();
  const { t } = useTranslation("common", { keyPrefix: "navbar" });
  const { unreadMessagesCount } = useChat();
  const sharedStoreContext = useSharedStoreContext();

  const isEmployee = containsEmployeeRole(user?.role);
  const canAccessProfessionalCoaching = useCanAccessProfessionalCoaching();
  const hasCoachingOnlyAllocation = useHasCoachingOnlyAllocation();
  const isOrganisationAdmin =
    user?.role?.includes(UserRole.ORGANISATION_ADMIN) ?? false;
  const isCompanionPageEnabled = useFeatureIsOn(
    `${FeatureFlagsKeys.COMPANION_PAGE}`,
  );
  const isProfessional = user?.role?.includes(UserRole.MHP) ?? false;
  const isClinicalSupervisor = containsSupervisorRole(user?.role);
  const isAdmin = user?.role?.includes(UserRole.ADMIN) ?? false;
  const isImpersonator = user?.role?.includes(UserRole.IMPERSONATOR) ?? false;
  const hasTeamSessionsOnly = user?.organisation?.hasTeamSessionsOnly ?? false;
  const crisisSupportEnabled =
    user?.organisation?.settings?.crisisSupportEnabled ?? false;
  const hasSurveyProfessionalNPSPendingSubmission =
    sharedStoreContext.surveyProfessionalNPS.pendingSubmissionId !== null;

  const {
    employeeLinks,
    organisationAdminLinks,
    professionalLinks,
    clinicalSupervisorLinks,
    impersonatorLinks,
    adminLinks,
  } = getNavbarLinks({
    user,
    isProfessional,
    canAccessProfessionalCoaching,
    isCompanionPageEnabled,
    unreadMessagesCount,
    crisisSupportEnabled,
    hasSurveyProfessionalNPSPendingSubmission,
    t,
  });

  let uniqueLinks = uniq([
    ...(isEmployee ? employeeLinks : []),
    ...(isOrganisationAdmin ? organisationAdminLinks : []),
    ...(isProfessional ? professionalLinks : []),
    ...(isClinicalSupervisor ? clinicalSupervisorLinks : []),
    ...(isAdmin ? adminLinks : []),
    ...(isImpersonator ? impersonatorLinks : []),
  ]);

  if (hasTeamSessionsOnly) {
    uniqueLinks = uniqueLinks.filter((link) =>
      [LINK_ORGANISATION_TEAM_SESSIONS_ID, LINK_PROFILE_ID].includes(
        link.id ?? "",
      ),
    );
  }

  if (hasCoachingOnlyAllocation) {
    uniqueLinks = uniqueLinks.filter((link) => link.id !== LINK_SUPPORT_ID);
  }

  return {
    status: "success",
    data: {
      isOrganisationAdmin,
      links: uniqueLinks,
    },
    effects: {},
  };
}

interface GetNavbarLinksData {
  clinicalSupervisorLinks: NavbarLink[];
  employeeLinks: NavbarLink[];
  professionalLinks: NavbarLink[];
  organisationAdminLinks: NavbarLink[];
  adminLinks: NavbarLink[];
  impersonatorLinks: NavbarLink[];
}

function buildHomeNavBarLink(
  t: TFunction,
  hasSurveyProfessionalNPSPendingSubmission: boolean,
) {
  return {
    activeRegex: "\\/$",
    href: "/",
    icon: <HomeIcon />,
    label: t("home"),
    role: Object.values(UserRole),
    type: NavbarLinkType.PRIMARY,
    id: "navbar-link-home",
    rightSideIcon: hasSurveyProfessionalNPSPendingSubmission && (
      <CounterDot count={1} />
    ),
  };
}

function buildMessagesNavBarLink(
  t: TFunction,
  isProfessional: boolean,
  unreadMessagesCount: number,
) {
  return {
    activeRegex: "/messages",
    href: "/messages",
    icon: <ChatBalloonIcon />,
    label: t("messages"),
    role: Object.values(UserRole),
    type: isProfessional ? NavbarLinkType.PRIMARY : NavbarLinkType.SECONDARY,
    rightSideIcon: unreadMessagesCount > 0 && (
      <CounterDot count={unreadMessagesCount} />
    ),
  };
}

function buildProfessionalClientsNavBarLink(
  t: TFunction,
  isProfessional: boolean,
): NavbarLink | null {
  if (!isProfessional) {
    return null;
  }

  return {
    activeRegex: "/professionals/clients",
    href: "/professionals/clients",
    icon: <UsersIcon />,
    label: t("clients"),
    role: [UserRole.MHP],
    type: NavbarLinkType.PRIMARY,
  };
}

function buildCrisisSupportNavBarLink(
  t: TFunction,
  crisisSupportEnabled: boolean,
) {
  if (!crisisSupportEnabled) {
    return null;
  }

  return {
    activeRegex: "/crisis-support",
    href: "/crisis-support",
    icon: <PhoneIcon color={tokens.colors["purple"][800]} />,
    label: t("crisisSupport"),
    role: Object.values(UserRole),
    type: NavbarLinkType.TERTIARY,
    color: "tertiary",
  };
}

function getNavbarLinks({
  user,
  isProfessional,
  unreadMessagesCount,
  canAccessProfessionalCoaching,
  crisisSupportEnabled,
  isCompanionPageEnabled,
  hasSurveyProfessionalNPSPendingSubmission,
  t,
}: GetNavbarLinksProps): GetNavbarLinksData {
  /**
   * This list has all the possible links in the navbar (employee, professional and admins).
   */
  const links: NavbarLink[] = [
    buildHomeNavBarLink(t, hasSurveyProfessionalNPSPendingSubmission),
    ...(isCompanionPageEnabled
      ? [
          {
            activeRegex: "/companion",
            href: "/companion",
            icon: <OliIcon />,
            label: t("companion"),
            role: [
              UserRole.EMPLOYEE,
              UserRole.ORGANISATION_ADMIN,
              UserRole.MANAGER,
            ],
            type: NavbarLinkType.PRIMARY,
            id: "navbar-link-companion",
            rightSideIcon: (
              <Tag variant="default" weight="bold" size="xs" theme="medium">
                {t("companionTag")}
              </Tag>
            ),
          },
        ]
      : []),
    {
      activeRegex: "/support",
      href: "/support",
      icon: <HeartIcon variant="pulse" />,
      label: t("oneToOne"),
      role: [UserRole.EMPLOYEE, UserRole.ORGANISATION_ADMIN, UserRole.MANAGER],
      type: NavbarLinkType.PRIMARY,
      id: LINK_SUPPORT_ID,
    },
    ...(canAccessProfessionalCoaching
      ? [
          {
            activeRegex: "/professional-coaching",
            href: "/professional-coaching",
            icon: <BriefCaseIcon />,
            label: t("coaching"),
            role: [
              UserRole.EMPLOYEE,
              UserRole.ORGANISATION_ADMIN,
              UserRole.MANAGER,
            ],
            type: NavbarLinkType.PRIMARY,
            id: "navbar-link-coaching",
          },
        ]
      : []),
    {
      activeRegex: "/self-guided",
      href: "/self-guided",
      icon: <MeditationIcon />,
      label: t("selfGuided"),
      role: [UserRole.EMPLOYEE, UserRole.ORGANISATION_ADMIN, UserRole.MANAGER],
      type: NavbarLinkType.PRIMARY,
      id: "navbar-link-self-guided",
    },
    buildProfessionalClientsNavBarLink(t, isProfessional),
    {
      activeRegex: "/professionals/availability",
      href: "/professionals/availability",
      icon: <CalendarIcon />,
      label: t("calendar"),
      role: [UserRole.MHP],
      type: NavbarLinkType.PRIMARY,
    },
    {
      activeRegex: "/professionals/library|/resources/*",
      href: "/professionals/library",
      icon: <ResourcesIcon />,
      label: t("resources"),
      role: [UserRole.MHP],
      type: NavbarLinkType.PRIMARY,
      id: "navbar-link-resources",
    },
    {
      href: INTERCOM_FAQ_URL,
      isExternal: true,
      label: t("faqs"),
      icon: <QuestionCircleIcon />,
      role: [UserRole.MHP],
      type: NavbarLinkType.PRIMARY,
    },
    // Org Admin section Links
    {
      activeRegex: "/organisation/reports",
      href: "/organisation/reports",
      icon: <PieChartIcon />,
      label: t("reports"),
      role: [UserRole.ORGANISATION_ADMIN],
      type: NavbarLinkType.ORGANISATION_ADMIN,
      id: "navbar-link-reports",
    },
    {
      activeRegex: PAGE_ROUTE.ORGANISATION.TEAM_SESSIONS.HOME,
      href: PAGE_ROUTE.ORGANISATION.TEAM_SESSIONS.HOME,
      icon: <GroupIcon color="currentColor" />,
      label: t("teamSessions"),
      role: [UserRole.ORGANISATION_ADMIN],
      type: NavbarLinkType.ORGANISATION_ADMIN,
      id: LINK_ORGANISATION_TEAM_SESSIONS_ID,
    },
    {
      activeRegex: "/organisation/members",
      href: "/organisation/members",
      icon: <UsersIcon />,
      label: t("members"),
      role: [UserRole.ORGANISATION_ADMIN],
      type: NavbarLinkType.ORGANISATION_ADMIN,
      id: "navbar-link-members",
    },
    {
      activeRegex: "/organisation/resources",
      href: "/organisation/resources",
      icon: <ResourcesIcon color="currentColor" />,
      label: t("organisationResources"),
      role: [UserRole.ORGANISATION_ADMIN],
      type: NavbarLinkType.ORGANISATION_ADMIN,
      id: "navbar-link-bm-resources",
    },
    // Administration section Links
    {
      activeRegex: "/admin/triaging-submissions",
      href: "/admin/triaging-submissions",
      label: t("matchingTool"),
      role: [
        UserRole.ADMIN,
        UserRole.CLINICAL_SUPERVISOR,
        UserRole.EXTERNAL_CLINICAL_SUPERVISOR,
      ],
      type: NavbarLinkType.ADMIN,
    },
    {
      activeRegex: "/admin/organisation-reports",
      href: "/admin/organisation-reports",
      label: t("organisationReports"),
      role: [UserRole.ADMIN],
      type: NavbarLinkType.ADMIN,
    },
    {
      href: METABASE_SESSION_LOG_URL,
      isExternal: true,
      label: t("sessionsLog"),
      role: [UserRole.ADMIN],
      type: NavbarLinkType.ADMIN,
    },
    {
      activeRegex: "/admin/detailed-professional-availability",
      href: "/admin/detailed-professional-availability",
      label: t("professionalAvailability"),
      role: [UserRole.ADMIN],
      type: NavbarLinkType.ADMIN,
    },
    {
      activeRegex: "/admin/case-management",
      href: "/admin/case-management",
      label: t("caseManagement"),
      role: [
        UserRole.ADMIN,
        UserRole.CLINICAL_SUPERVISOR,
        UserRole.EXTERNAL_CLINICAL_SUPERVISOR,
      ],
      type: NavbarLinkType.ADMIN,
    },
    {
      activeRegex: "/admin/impersonate",
      href: "/admin/impersonate",
      label: t("impersonate"),
      role: [UserRole.IMPERSONATOR],
      type: NavbarLinkType.ADMIN,
    },
    {
      activeRegex: "/admin/instant-session",
      href: "/admin/instant-session",
      label: t("instantSession"),
      role: [UserRole.ADMIN],
      type: NavbarLinkType.ADMIN,
    },
    buildMessagesNavBarLink(t, isProfessional, unreadMessagesCount),
    {
      activeRegex: "/profile",
      href: "/profile",
      icon: <UserIcon />,
      label: user?.name ?? t("profile"),
      role: Object.values(UserRole),
      type: NavbarLinkType.SECONDARY,
      id: LINK_PROFILE_ID,
    },
    buildCrisisSupportNavBarLink(t, crisisSupportEnabled),
  ].filter(Boolean) as NavbarLink[];

  const employeeLinks = links.filter((link) =>
    link.role.some((role) =>
      ([UserRole.EMPLOYEE, UserRole.MANAGER] as UserRole[]).includes(role),
    ),
  );
  const organisationAdminLinks = links.filter((link) =>
    link.role.some((role) =>
      ([UserRole.ORGANISATION_ADMIN] as UserRole[]).includes(role),
    ),
  );
  const professionalLinks = links.filter((link) =>
    link.role.some((role) => ([UserRole.MHP] as UserRole[]).includes(role)),
  );
  const clinicalSupervisorLinks = links.filter((link) =>
    containsSupervisorRole(link.role),
  );
  const impersonatorLinks = links.filter((link) =>
    link.role.includes(UserRole.IMPERSONATOR),
  );

  const adminLinks = links.filter((link) =>
    link.role.some((role) => ([UserRole.ADMIN] as UserRole[]).includes(role)),
  );

  return {
    clinicalSupervisorLinks,
    employeeLinks,
    organisationAdminLinks,
    professionalLinks,
    impersonatorLinks,
    adminLinks,
  };
}
