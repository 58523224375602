import ctl from "@netlify/classnames-template-literals";

const gradientBg = ctl(`
  bg-gradient-ellipse-tr 
  from-bg-purple-500 
  via-bg-purple-800 
  to-bg-purple-900
`);

export const postSessionSatisfactionWrapper = (isLight: boolean) =>
  ctl(`
  h-full
  flex
  flex-col
  items-center
  gap-y-8
  p-16
  px-6
  md:px-0
  ${isLight ? "bg-white" : gradientBg}
  ${isLight ? "text-black" : "text-white"}
  ${isLight ? "md:gap-y-8" : "md:gap-y-14"}
  ${isLight ? "p-4 md:py-2" : "md:py-20"}
`);

export const postSessionSatisfactionScoreWrapper = (isLight: boolean) =>
  ctl(`
  flex
  items-center
  gap-6
  md:gap-8
  ${isLight ? "pb-4" : "pb-8"} 
 `);

export const postSessionSatisfactionButtonWrapper = (isLight: boolean) =>
  ctl(`
  w-[22rem] 
  flex 
  flex-col
  gap-4
  justify-items-center
  ${isLight ? "max-w-3xs" : "max-w-sm"} 
 `);

export const crossButtonWrapper = ctl(`
  absolute
  top-4
  right-4
  lg:top-16
  lg:right-36
`);

export const lowScoreFormWrapper = ctl(`
  relative
  flex
  flex-col
  gap-6
  max-w-md
`);
