/**
 * @generated SignedSource<<900876734eff2898d670939f1a948810>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PostSessionSatisfactionSurveySubmitMutation$variables = {
  score: number;
  scoreReason?: string | null | undefined;
  sessionId?: string | null | undefined;
  threadId?: string | null | undefined;
};
export type PostSessionSatisfactionSurveySubmitMutation$data = {
  readonly savePostSessionSatisfactionSurvey: {
    readonly reason?: string;
    readonly success?: boolean;
  };
};
export type PostSessionSatisfactionSurveySubmitMutation = {
  response: PostSessionSatisfactionSurveySubmitMutation$data;
  variables: PostSessionSatisfactionSurveySubmitMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "score"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scoreReason"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sessionId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "threadId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "score",
    "variableName": "score"
  },
  {
    "kind": "Variable",
    "name": "scoreReason",
    "variableName": "scoreReason"
  },
  {
    "kind": "Variable",
    "name": "sessionId",
    "variableName": "sessionId"
  },
  {
    "kind": "Variable",
    "name": "threadId",
    "variableName": "threadId"
  }
],
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "success",
      "storageKey": null
    }
  ],
  "type": "PostSessionSatisfactionSurveySaved",
  "abstractKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    }
  ],
  "type": "ForbiddenError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PostSessionSatisfactionSurveySubmitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "savePostSessionSatisfactionSurvey",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "PostSessionSatisfactionSurveySubmitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "savePostSessionSatisfactionSurvey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "73084b61b45261a6e7822f5067f51dd0",
    "id": null,
    "metadata": {},
    "name": "PostSessionSatisfactionSurveySubmitMutation",
    "operationKind": "mutation",
    "text": "mutation PostSessionSatisfactionSurveySubmitMutation(\n  $sessionId: ID\n  $score: Int!\n  $threadId: ID\n  $scoreReason: String\n) {\n  savePostSessionSatisfactionSurvey(sessionId: $sessionId, threadId: $threadId, score: $score, scoreReason: $scoreReason) {\n    __typename\n    ... on PostSessionSatisfactionSurveySaved {\n      success\n    }\n    ... on ForbiddenError {\n      reason\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "64ea28de02c92a4234b5107486a152a3";

export default node;
