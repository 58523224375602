/**
 * @generated SignedSource<<b58ded40dc9f890d309961e6a3bb59a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PostSessionSatisfactionSurveySkipMutation$variables = {
  sessionId?: string | null | undefined;
  threadId?: string | null | undefined;
};
export type PostSessionSatisfactionSurveySkipMutation$data = {
  readonly skipPostSessionSatisfactionSurvey: {
    readonly reason?: string;
    readonly success?: boolean;
  };
};
export type PostSessionSatisfactionSurveySkipMutation = {
  response: PostSessionSatisfactionSurveySkipMutation$data;
  variables: PostSessionSatisfactionSurveySkipMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sessionId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "threadId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "sessionId",
    "variableName": "sessionId"
  },
  {
    "kind": "Variable",
    "name": "threadId",
    "variableName": "threadId"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "success",
      "storageKey": null
    }
  ],
  "type": "PostSessionSatisfactionSurveySkipped",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    }
  ],
  "type": "ForbiddenError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PostSessionSatisfactionSurveySkipMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "skipPostSessionSatisfactionSurvey",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PostSessionSatisfactionSurveySkipMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "skipPostSessionSatisfactionSurvey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ec8bd0fb8939f318405910334cd0d5e5",
    "id": null,
    "metadata": {},
    "name": "PostSessionSatisfactionSurveySkipMutation",
    "operationKind": "mutation",
    "text": "mutation PostSessionSatisfactionSurveySkipMutation(\n  $sessionId: ID\n  $threadId: ID\n) {\n  skipPostSessionSatisfactionSurvey(sessionId: $sessionId, threadId: $threadId) {\n    __typename\n    ... on PostSessionSatisfactionSurveySkipped {\n      success\n    }\n    ... on ForbiddenError {\n      reason\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "30a5438a48e2ddac426f209cbb18fbd1";

export default node;
