import { IconProps, SvgIcon } from "./SvgIcon";

export function OliIcon({ className, fill, size }: IconProps): JSX.Element {
  return (
    <SvgIcon
      fill={fill}
      size={size}
      className={`rounded-full ${className || ""}`}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_f_1_4)">
          <g
            clipPath="url(#paint0_angular_1_4_clip_path)"
            data-figma-skip-parse="true"
          >
            <g transform="matrix(0.00513795 -0.00766718 0.00766718 0.00513795 12 12)">
              <foreignObject
                x="-1841.94"
                y="-1841.94"
                width="3683.89"
                height="3683.89"
              >
                <div
                  style={{
                    background:
                      "conic-gradient(from 90deg,rgba(224, 105, 134, 1) 0deg,rgba(169, 215, 152, 1) 90deg,rgba(146, 211, 236, 1) 180deg,rgba(255, 185, 165, 1) 270deg,rgba(224, 105, 134, 1) 360deg)",
                    height: "100%",
                    width: "100%",
                    opacity: 1,
                  }}
                ></div>
              </foreignObject>
            </g>
          </g>
          <ellipse
            cx="12"
            cy="12"
            rx="11"
            ry="11"
            transform="rotate(-90 12 12)"
            data-figma-gradient-fill="{&#34;type&#34;:&#34;GRADIENT_ANGULAR&#34;,&#34;stops&#34;:[{&#34;color&#34;:{&#34;r&#34;:0.87843137979507446,&#34;g&#34;:0.41176471114158630,&#34;b&#34;:0.52549022436141968,&#34;a&#34;:1.0},&#34;position&#34;:0.0},{&#34;color&#34;:{&#34;r&#34;:0.66274511814117432,&#34;g&#34;:0.84313726425170898,&#34;b&#34;:0.59607845544815063,&#34;a&#34;:1.0},&#34;position&#34;:0.250},{&#34;color&#34;:{&#34;r&#34;:0.57254904508590698,&#34;g&#34;:0.82745099067687988,&#34;b&#34;:0.92549020051956177,&#34;a&#34;:1.0},&#34;position&#34;:0.50},{&#34;color&#34;:{&#34;r&#34;:1.0,&#34;g&#34;:0.72549021244049072,&#34;b&#34;:0.64705884456634521,&#34;a&#34;:1.0},&#34;position&#34;:0.750}],&#34;stopsVar&#34;:[{&#34;color&#34;:{&#34;r&#34;:0.87843137979507446,&#34;g&#34;:0.41176471114158630,&#34;b&#34;:0.52549022436141968,&#34;a&#34;:1.0},&#34;position&#34;:0.0},{&#34;color&#34;:{&#34;r&#34;:0.66274511814117432,&#34;g&#34;:0.84313726425170898,&#34;b&#34;:0.59607845544815063,&#34;a&#34;:1.0},&#34;position&#34;:0.250},{&#34;color&#34;:{&#34;r&#34;:0.57254904508590698,&#34;g&#34;:0.82745099067687988,&#34;b&#34;:0.92549020051956177,&#34;a&#34;:1.0},&#34;position&#34;:0.50},{&#34;color&#34;:{&#34;r&#34;:1.0,&#34;g&#34;:0.72549021244049072,&#34;b&#34;:0.64705884456634521,&#34;a&#34;:1.0},&#34;position&#34;:0.750}],&#34;transform&#34;:{&#34;m00&#34;:10.275900840759277,&#34;m01&#34;:15.334365844726562,&#34;m02&#34;:-0.80513346195220947,&#34;m10&#34;:-15.334352493286133,&#34;m11&#34;:10.275902748107910,&#34;m12&#34;:14.529224395751953},&#34;opacity&#34;:1.0,&#34;blendMode&#34;:&#34;NORMAL&#34;,&#34;visible&#34;:true}"
          />
        </g>
        <g filter="url(#filter1_f_1_4)">
          <ellipse
            cx="12"
            cy="12"
            rx="6"
            ry="6"
            transform="rotate(-90 12 12)"
            fill="#7661C9"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_1_4"
            x="0"
            y="0"
            width="24"
            height="24"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="0.5"
              result="effect1_foregroundBlur_1_4"
            />
          </filter>
          <clipPath id="paint0_angular_1_4_clip_path">
            <ellipse
              cx="12"
              cy="12"
              rx="11"
              ry="11"
              transform="rotate(-90 12 12)"
            />
          </clipPath>
          <filter
            id="filter1_f_1_4"
            x="-2"
            y="-2"
            width="28"
            height="28"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="4"
              result="effect1_foregroundBlur_1_4"
            />
          </filter>
        </defs>
      </svg>
    </SvgIcon>
  );
}
