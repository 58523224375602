import {
  Avatar,
  Button,
  StarIcon,
  Text,
  toast,
  TextArea,
  CrossIcon,
} from "@olivahealth/oli-ui";
import tokens from "@olivahealth/oli-ui/theme/tokens";
import React, { useState } from "react";
import { SpeechStarIcon } from "@olivahealth/oli-ui/src/atoms/Icons/SpeechStar";
import { graphql, useMutation } from "react-relay";
import logger from "@olivahealth/logger/client";
import { useForm } from "react-hook-form";
import Image from "../../components/common/Image";
import useTranslation, { TFunction } from "../../hooks/useTranslation";
import useSmallScreenDetection from "../../hooks/useSmallScreenDetection";
import { PostSessionSatisfactionSurveySubmitMutation } from "./__generated__/PostSessionSatisfactionSurveySubmitMutation.graphql";
import { PostSessionSatisfactionSurveySkipMutation } from "./__generated__/PostSessionSatisfactionSurveySkipMutation.graphql";
import * as s from "./styles";

interface IProps {
  professionalName?: string;
  professionalImage?: string;
  isOli?: boolean;
  variant?: "dark" | "light";
  sessionId?: string;
  threadId?: string;
  onSatisfactionSkipped?: () => void;
  onSatisfactionSubmitted?: () => void;
}

interface FormValues {
  scoreReason: string;
}

interface ReasonQuestionProps {
  isLight: boolean;
  t: TFunction;
  getRatingTitle: () => string;
  scoreReason: string;
  setScoreReason: (value: string) => void;
  onSubmit: () => void;
}

const SavePostSessionSatisfactionSurveyMutation = graphql`
  mutation PostSessionSatisfactionSurveySubmitMutation(
    $sessionId: ID
    $score: Int!
    $threadId: ID
    $scoreReason: String
  ) {
    savePostSessionSatisfactionSurvey(
      sessionId: $sessionId
      threadId: $threadId
      score: $score
      scoreReason: $scoreReason
    ) {
      ... on PostSessionSatisfactionSurveySaved {
        success
      }
      ... on ForbiddenError {
        reason
      }
    }
  }
`;

const SkipPostSessionSatisfactionSurveyMutation = graphql`
  mutation PostSessionSatisfactionSurveySkipMutation(
    $sessionId: ID
    $threadId: ID
  ) {
    skipPostSessionSatisfactionSurvey(
      sessionId: $sessionId
      threadId: $threadId
    ) {
      ... on PostSessionSatisfactionSurveySkipped {
        success
      }
      ... on ForbiddenError {
        reason
      }
    }
  }
`;

export default function PostSessionSatisfactionSurvey({
  professionalName,
  professionalImage,
  variant = "dark",
  isOli = false,
  sessionId,
  threadId,
  onSatisfactionSkipped,
  onSatisfactionSubmitted,
}: IProps) {
  const { t } = useTranslation("postSessionSatisfaction");
  const isMobile = useSmallScreenDetection();
  const [rating, setRating] = useState<number | null>(null);
  const [scoreReason, setScoreReason] = useState<string>("");
  const [showReasonForm, setShowReasonForm] = useState<boolean>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [isSkipDisabled, setIsSkipDisabled] = useState<boolean>(false);
  const isLight = variant === "light";

  const [saveMutation] =
    useMutation<PostSessionSatisfactionSurveySubmitMutation>(
      SavePostSessionSatisfactionSurveyMutation,
    );
  const [skipMutation] = useMutation<PostSessionSatisfactionSurveySkipMutation>(
    SkipPostSessionSatisfactionSurveyMutation,
  );

  const onSubmitCallback = () => {
    if (onSatisfactionSubmitted) {
      onSatisfactionSubmitted();
    }
  };

  const onSkipCallback = () => {
    if (onSatisfactionSkipped) {
      onSatisfactionSkipped();
    }
  };

  const submitSatisfactionSurvey = () => {
    if (!rating) {
      return;
    }

    if (rating < 5 && !showReasonForm) {
      setShowReasonForm(true);
      return;
    }
    setIsSubmitDisabled(true);
    saveMutation({
      variables: {
        sessionId,
        threadId,
        score: rating,
        scoreReason: rating < 5 ? scoreReason : undefined,
      },
      onCompleted: () => {
        onSubmitCallback();
      },
      onError: (error) => {
        logger.error(
          "surveys/postSessionSatisfaction",
          "Unknown error completing the session satisfaction.",
          {
            rating,
            scoreReason,
            sessionId,
            threadId,
            error,
          },
        );
        onSubmitCallback();
        setIsSubmitDisabled(false);
      },
    });
    toast({
      body: t("successToast"),
      variant: "success",
    });
  };
  const skipSatisfactionSurvey = () => {
    setIsSkipDisabled(true);
    skipMutation({
      variables: { sessionId, threadId },
      onCompleted: () => {
        onSkipCallback();
      },
      onError: (error) => {
        logger.error(
          "surveys/postSessionSatisfaction",
          "Unknown error skipping the session satisfaction.",
          {
            sessionId,
            threadId,
            error,
          },
        );
        onSkipCallback();
        setIsSkipDisabled(false);
      },
    });
  };

  const getRatingTitle = () => {
    const titles = {
      1: "ratingTitle.terrible",
      2: "ratingTitle.poor",
      3: "ratingTitle.okay",
      4: "ratingTitle.good",
      5: "ratingTitle.excellent",
    };
    return t(rating !== null ? titles[rating] : "ratingTitle.default");
  };

  const handleStarClick = (starRating: number) => {
    setRating((prev) => (prev === starRating ? null : starRating));
  };

  if (!sessionId && !threadId) {
    return;
  }

  return (
    <div className={s.postSessionSatisfactionWrapper(isLight)}>
      {showReasonForm && (
        <div className={s.crossButtonWrapper}>
          <Button
            variant="tertiaryInverse"
            onClick={submitSatisfactionSurvey}
            aria-label="Close"
          >
            <CrossIcon color="white" />
          </Button>
        </div>
      )}
      {!isLight && (
        <Text variant="body" weight="bold">
          {t("header")}
        </Text>
      )}
      <div className="relative">
        {professionalImage ? (
          <Image
            src={professionalImage}
            alt={t("imageAlt")}
            className="rounded-full object-cover"
            width={isMobile || isLight ? 64 : 96}
            height={isMobile || isLight ? 64 : 96}
          />
        ) : (
          <Avatar
            size={isMobile || isLight ? "md" : "l"}
            label={isOli ? "Oli" : professionalName}
            color={isOli ? "white" : "purple"}
            variant="avatar"
          />
        )}
        <SpeechStarIcon
          color={tokens.colors["accent-blue"][400]}
          size={isMobile || isLight ? 26 : 36}
          className="absolute -top-2 -right-2 z-10"
        />
      </div>
      {showReasonForm ? (
        <LowScoreReasonForm
          isLight={isLight}
          t={t}
          getRatingTitle={getRatingTitle}
          scoreReason={scoreReason}
          setScoreReason={setScoreReason}
          onSubmit={submitSatisfactionSurvey}
        />
      ) : (
        <>
          <div className="flex flex-col gap-6 items-center">
            <Text weight="bold" align="center" size={isLight ? "2xl" : "3xl"}>
              {isLight
                ? t("resendTitle", {
                    professionalName: isOli ? "Oli" : professionalName,
                  })
                : t("title", {
                    professionalName: isOli ? "Oli" : professionalName,
                  })}
            </Text>
            <div className="max-w-md">
              <Text
                variant="body"
                align="center"
                size="base"
                color={isLight ? "primary-light" : "white"}
              >
                {t("subtitle")}
              </Text>
            </div>
          </div>

          <div className="flex flex-col gap-4 md:gap-8">
            <Text size={isLight ? "base" : "xl"} variant="body" align="center">
              {getRatingTitle()}
            </Text>
            <div className={s.postSessionSatisfactionScoreWrapper(isLight)}>
              {[1, 2, 3, 4, 5].map((star) => (
                <button key={star} onClick={() => handleStarClick(star)}>
                  <StarIcon
                    key={star}
                    size={isMobile || isLight ? 26 : 32}
                    color={
                      rating !== null && rating >= star
                        ? tokens.colors["status-yellow"][500]
                        : isLight
                          ? "black"
                          : "white"
                    }
                    fill={
                      rating !== null && rating >= star
                        ? tokens.colors["status-yellow"][500]
                        : "transparent"
                    }
                  />
                </button>
              ))}
            </div>
          </div>
        </>
      )}
      <div className={s.postSessionSatisfactionButtonWrapper(isLight)}>
        <Button
          disabled={!rating || isSubmitDisabled}
          width={"full"}
          variant={isLight ? "success" : "green"}
          onClick={submitSatisfactionSurvey}
        >
          {t("buttonLabel")}
        </Button>
        {isLight && !showReasonForm && (
          <Button
            disabled={isSkipDisabled}
            variant="icon"
            justify="center"
            onClick={skipSatisfactionSurvey}
          >
            {t("buttonSkipLabel")}
          </Button>
        )}
      </div>
    </div>
  );
}

const LowScoreReasonForm = ({
  isLight,
  t,
  getRatingTitle,
  scoreReason,
  setScoreReason,
  onSubmit,
}: ReasonQuestionProps) => {
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      scoreReason: "",
    },
    mode: "onChange",
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={s.lowScoreFormWrapper}>
        <Text weight="bold" align="center" size={isLight ? "2xl" : "3xl"}>
          {t("scoreReasonTitle", { ratingTitle: getRatingTitle() })}
        </Text>
        <TextArea
          id="scoreReason"
          value={scoreReason}
          register={register}
          onChange={(e) => setScoreReason(e.target.value)}
          placeholder={t("scoreReasonPlaceholder")}
          minRows={3}
          variant={isLight ? "light" : "dark"}
        />
        <Text size="sm" color={isLight ? "secondary" : "light"} align="center">
          {t("scoreReasonSubLabel")}
        </Text>
      </div>
    </form>
  );
};
