import { useUserData } from "../services/contexts/UserDataContext";

/**
 * Hook to determine if the current user's organization only has access to coaching sessions
 * and no other types of sessions (one-to-one, therapy). When this returns true, we should
 * hide the Support tab from the navbar since it's not applicable to coaching-only users.
 */
export default function useHasCoachingOnlyAllocation(): boolean {
  const { data: user } = useUserData();

  // Use the pre-computed value from the GraphQL API
  return user?.maxNumberOfServices?.hasCoachingOnlyAllocation ?? false;
}
